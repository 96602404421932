import React from "react";
import cTabletImage from "../../assets/images/c-tablet.png";
import useToggle from "../../Hooks/useToggle";
import BackToTop from "../BackToTop";
import FooterHomeOne from "../HomeOne/FooterHomeOne";
import HomeOneHeader from "../HomeOne/HomeOneHeader";
import Drawer from "../Mobile/Drawer";
import HeroNews from "../News/HeroNews";

const Ctablet = () => {
  const [drawer, drawerAction] = useToggle(false);
  return (
    <>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <HomeOneHeader action={drawerAction.toggle} />
      <HeroNews
        title="C-Tablet"
        breadcrumb={[
          { link: "/", title: "Home" },
          { link: "/c-tablet", title: "C-Tablet" },
        ]}
      />
      <div
        style={{
          background: "#eef1f6",
        }}
      >
        <div className="container">
          <div className="mb-0 row justify-content-between align-items-center">
            <div className="col-lg-6 col-sm-12 mt-sm-5">
              <h2 className="m-3">Introducing the C-Tablet</h2>
              <p className="m-3">
                Featuring the all new C-Tablet by HELIX, a revolutionary device
                that will change the way you interact with your tablet Powered
                by Android 11.
                <br />
                <br />
                The C-Tablet is a 10.1 inch tablet with 1920x1200 resolution IPS
                Gorilla Glass Display with Blazing fast Processor powered by 8
                Core SC 9863A chipset and comes with 4GB of RAM and 64GB of
                internal storage.
              </p>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div>
                <img
                  src={cTabletImage}
                  alt="c-tablet"
                  className=" m-5"
                  style={{
                    height: "500px",
                    width: "500px",
                    borderRadius: 20,
                  }}
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="row my-5">
            <div className="col-12">
              <h2 className="m-3">Features</h2>
              <div className="row ">
                <div className="col-6">
                  <ul>
                    <li className="m-3">
                      <p>&bull; &nbsp; 8MP Rear Camera</p>
                    </li>
                    <li className="m-3">
                      <p>&bull; &nbsp; 5MP Front Camera</p>
                    </li>
                    <li className="m-3">
                      <p>&bull; &nbsp; 2G, 3G, 4G LTE Network</p>
                    </li>
                    <li className="m-3">
                      <p>&bull; &nbsp; 2.4GHz + 5GHz, Wi-Fi Connectivity </p>
                    </li>
                    <li className="m-3">
                      <p>&bull; &nbsp; Bluetooth 5.1</p>
                    </li>
                  </ul>
                </div>
                <div className="col-6">
                  <ul>
                    <li className="m-3">
                      <p>&bull; &nbsp; Android 11</p>
                    </li>
                    <li className="m-3">
                      <p>&bull; &nbsp; 1.6GHz Octa-Core Processor</p>
                    </li>
                    <li className="m-3">
                      <p>&bull; &nbsp; 6000mAh Battery</p>
                    </li>
                    <li className="m-3">
                      <p>&bull; &nbsp; Supports USB Docking</p>
                    </li>
                    <li className="m-3">
                      <p>&bull; &nbsp; Stereo Speaker System</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <h2 className="my-5 text-center">Core Specification</h2>
          <div className="text-center row justify-content-center align-items-center">
            <div className="col-4">
              <div> <img src="https://cdn-icons-png.flaticon.com/512/4738/4738920.png" alt="" width={90} /></div>
              <h4 className="m-3">Processor</h4>
              <p className="m-3">8 Core SC 9863A 1.6GHz</p>
            </div>
            <div className="col-4">
              <div> <img src="https://cdn-icons-png.flaticon.com/512/53/53128.png" alt="" width={90} /></div>
              <h4 className="m-3">Memory</h4>
              <p className="m-3">4GB RAM</p>
            </div>
            <div className="col-4">
              <div> <img src="https://cdn-icons-png.flaticon.com/512/1383/1383507.png" alt="" width={90} /></div>
              <h4 className="m-3">Storage Disk</h4>
              <p className="m-3">64GB ROM</p>
            </div>
            <div className="col-4">
              <div> <img src="https://cdn-icons-png.flaticon.com/512/1023/1023471.png" alt="" width={90} /></div>
              <h4 className="m-3">Display</h4>
              <p className="m-3">10.1 inches IPS, Gorilla Glass</p>
            </div>
            <div className="col-4">
              <div> <img src="https://cdn-icons-png.flaticon.com/512/3103/3103384.png" alt="" width={90} /></div>
              <h4 className="m-3">Battery</h4>
              <p className="m-3">6000mAh</p>
            </div>

            <div className="col-4">
              <div> <img src="https://cdn-icons-png.flaticon.com/512/8338/8338815.png" alt="" width={90} /></div>
              <h4 className="m-3">Expandable  Storage</h4>
              <p className="m-3">up to 128GB micro SD</p>
            </div>
          </div>
        </div>
        <hr />
      </div>

      <FooterHomeOne />
      <BackToTop />
    </>
  );
};

export default Ctablet;
