import React from "react";
import { Link } from "react-router-dom";

function Navigation() {
  return (
    <>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <a href="#">
            Product <i className="fal fa-angle-down" />
          </a>
          <ul className="sub-menu">
            <li>
              <Link to="/c-tablet">C-Tablet</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/about-us">About Us</Link>
        </li>
        {/* <li>
          <Link to="/contact">Contact Us</Link>
        </li> */}
        <li>
          <Link to="/helpdesk">Helpdesk</Link>
        </li>
      </ul>
    </>
  );
}

export default Navigation;
