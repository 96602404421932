import React from "react";
import useToggle from "../../Hooks/useToggle";
import BackToTop from "../BackToTop";
import FooterHomeOne from "../HomeOne/FooterHomeOne";
import Drawer from "../Mobile/Drawer";
import HeaderNews from "../News/HeaderNews";
import HeroNews from "../News/HeroNews";

function Helpdesk() {
  const [drawer, drawerAction] = useToggle(false);

  return (
    <>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <HeaderNews drawer={drawer} action={drawerAction.toggle} />
      <HeroNews
        title="HELIX Helpdesk"
        breadcrumb={[
          { link: "/", title: "Home" },
          { link: "/helpdesk", title: "Helpdesk" },
        ]}
      />
      <div
        style={{
          background: "#eef1f6",
        }}
        className="py-5"
      >
        <div className="container">
          <div className="pb-5">
            <h2 className="m-3">Welcome to HELIX Helpdesk</h2>
            <p className="m-3">
              HELIX Helpdesk is a web-based helpdesk system powered by{" "}
              <strong>osTicket</strong> which allows you to create support
              tickets and customer requests. It is a powerful, yet easy to use,
              helpdesk system that can be used by anyone.
            </p>
            <div>
              <button
                type="button"
                className="btn btn-primary m-3"
                onClick={() =>
                  window.open("http://helpdesk.helix.ph", "_blank")
                }
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
        <hr/>
      </div>
      <FooterHomeOne />
      <BackToTop />
    </>
  );
}

export default Helpdesk;
