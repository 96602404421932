import React from "react";
import { Link } from "react-router-dom";
// import logo from "../../assets/images/logo.png";
import logo from "../../assets/images/helix-logo-text.jpg";

function FooterHomeOne({ className }) {
  return (
    <>
      <section className={`appie-footer-area ${className || ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-about-widget">
                <div className="logo">
                  <a href="#">
                    <img src={logo} alt="" />
                  </a>
                </div>
                <p>
                  Holistic Educational Learning Innovation Exponents (HELIX) Inc.
                </p>
                <a href="#">
                  Read More <i className="fal fa-arrow-right" />
                </a>
                <div className="social mt-30">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="footer-navigation">
                <h4 className="title">Company</h4>
                <ul>
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                  <li>
                    <a href="http://helpdesk.helix.ph" target="blank">Helpdesk</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-navigation">
                <h4 className="title">Branches</h4>
                <ul>
                  <li>
                    <a href="#" className="">Quezon City</a>
                  </li>
                  <li>
                    <a href="#">Cebu City</a>
                  </li>
                  <li>
                    <a href="#">Cagayan de Oro City</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-widget-info">
                <h4 className="title">Get In Touch</h4>
                <ul>
                  <li>
                    <a href="#">
                      <i className="fal fa-envelope" /> technn@gmail.com
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-envelope" /> encodes.helix@gmail.com
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-phone" /> Tel. (088) 882 0434
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-phone" /> Globe (0917) 682 8884
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-phone" /> SMART (0928) 507 6179
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-map-marker-alt" /> MNL. Unit 7 Commercial Bliss Teacher Bliss Quezon City
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-map-marker-alt" />A.s. Fortuna Banilad Mandaue Cebu City, 6000
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-map-marker-alt" /> Reddoorz Gmelina St. Carmen, Cagayan de Oro City, 9000
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                <div className="apps-download-btn">
                  {/* <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-apple" /> Download for iOS
                                            </a>
                                        </li>
                                        <li>
                                            <a className="item-2" href="#">
                                                <i className="fab fa-google-play" /> Download for
                                                Android
                                            </a>
                                        </li>
                                    </ul> */}
                </div>
                <div className="copyright-text">
                  <p>Copyright © 2022 HELIX, Inc. All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FooterHomeOne;
